import * as React from "react";
import { Link } from "gatsby";

const WorkBox = ({ el, index }) => {
  let isHover = false;
  const url = el?.slug?.current;
  const imageUrl = el.mainImage?.asset?.url;
  const imageSbiaditaUrl = el.mainImageSbiadita?.asset?.url;
  let imageToUse = isHover ? imageUrl : imageSbiaditaUrl;

  const toggleHover = (element) => {
    imageToUse = isHover ? imageUrl : imageSbiaditaUrl;
    const found=window.document.getElementById("wb-container-"+index);
    if(found){
        found.style.backgroundImage = `url(${imageToUse})`;
        window.document.getElementById("wb-span-"+index).style.opacity = isHover ? 0 : 1;
    }
   
  };
  const hoverOn = (element) => {
    isHover = true;
    toggleHover(element);
  };

  const hoverOff = (element) => {
    isHover = false;
    toggleHover(element);
  };

  const boxStyle = {
    backgroundImage: "url(" + imageToUse + ")",
    backgroundSize: "cover",
    backgroundPosition:"center"
  };

  return (
    <div >
      <Link to={url}>
        <div
          onMouseEnter={hoverOn}
          onMouseLeave={hoverOff}
          class={"hover:opacity-100 flex items-end 2xl:h-[600px] xl:h-[400px] lg:h-[400px] h-[350px]"} id={"wb-container-" + index}
          style={boxStyle}
        >
          <span class={"pb-5 pl-5 w-4/5 text-white "} id={"wb-span-" + index}>
            <span class="uppercase ">{(el.title||"").split("<").join("\n")}</span> <br></br>
            <span class="capitalize whitespace-pre-wrap">{(el.claim||"").split("<").join("\n")}</span>
          </span>
        </div>
      </Link>
    </div>
  );
};

export default WorkBox;
