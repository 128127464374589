import * as React from "react";
import { Link } from "gatsby";

const footerText = 'Copyright © ALFERCOM S.r.l. P.IVA 00000000000000000000 Cap. Sociale €10.000,00'

// const Footer = () => {
//   const footerClassName = ' bold w-full border-r-8 border-gunmetal vertical-text flex items-center bg-app-gray-light';
//   return (
//     <div className=" w-20  h-full hidden md:flex">
//       <div className={footerClassName} ><span className="h-full text-center">{footerText}</span></div>
//     </div>
//   )
// }

// export default Footer

export const Footer = () => {

  const currentYear= new Date().getFullYear();

  return (
   <div>
     <div class="w-full flex flex-row justify-between content-end mb-5">
      <div class="2xl:pl-40 xl:pl-40 lg:pl-20 pl-10 flex-column 2xl:text-2xl xl:text-xl text-footer-text">
        <div>&copy; {currentYear}</div>
        <div>0039 s.r.l.</div>
        <div>info@0039studio.com</div>
      </div>
      <div class="uppercase 2xl:text-7xl xl:text-6xl lg:text-5xl text-5xl font-bold underline margin-setts
       2xl:pr-40 xl:pr-40 lg:pr-20 pr-10" onClick={()=>goUp()}>UP</div>
     </div>

   </div>
  )
}
const goUp =  () => {
  window.scrollTo(0, 0);
}