import * as React from "react";
import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { Footer } from "../components/footer";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import WorkBox from "../components/work-box";
import { AnchorLink } from "gatsby-plugin-anchor-links";



// markup
const Work = ({ data }) => {
  const postsToSort = data?.allSanityPost?.nodes.filter(el=>el.publish);
  const posts = postsToSort?.sort((a, b) => {
    //sort by priority
    if (a.priority > b.priority) {
      return -1;
    }
    if (a.priority < b.priority) {
      return 1;
    }
  });

  return (
    <div>
      <Helmet>
          <title>0039 STUDIO - Works</title>
        </Helmet>
        

      <div id="header" name="header" class="lg:min-h-screen bg-white-100 flex lg:flex-row flex-col lg:justify-center ">
        <div class="lg:min-h-screen lg:w-5/12 lg:min-w-0 min-w-full bg-white 2xl:pl-40 xl:pl-40 lg:pl-20 lg:pt-20 flex flex-col z-10">
        <div class="lg:pl-0 lg:absolute lg:top-20 lg:pt-0 pt-5 pb-5 lg:pb-10 flex justify-center">
        <AnchorLink to="/#menu" stripHash>
              <StaticImage
                src="../images/logo.png"
                height="100"
                placeholder="tracedSVG"
                quality="100"
                alt="head-logo"
              ></StaticImage>
              </AnchorLink>
          </div>
          <div class="text-black opacity-30 mt-auto pb-20 lg:block hidden">
            <ul class="uppercase font-bold top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal text-6xl ">
              <Link to="/studio">
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  STUDIO
                </li>
              </Link>
              <Link to="/about">
                {" "}
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  ABOUT
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <div class="min-h-screen lg:w-7/12 lg:min-w-0 min-w-full bg-black lg:block  2xl:pl-40 xl:pl-40 lg:pl-20   flex flex-row  justify-center lg:flex hidden text-white bg-work">
          <div class="mt-auto pb-20">
            {/* <div class="uppercase font-bold text-7xl transition hover:translate-x-6 cursor-pointer flex-column">
              <div>Click</div> <div>here</div> <div>to design</div>{" "}
              <div>your story</div>
            </div> */}
          </div>

          <div class="top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal font-bold lg:absolute lg:top-40 lg:right-20 lg:text-6xl md:text-5xl text-4xl flex flex-col justify-center ">
            <div class="transform -rotate-90 lg:block hidden">
              <div>WORK</div>
            </div>
            <div class="lg:hidden block text-white text-center min-w-full flex flex-col justify-center">
              <div>WORK</div>
            </div>
          </div>
        </div>
      </div>

      <div class=" bg-white-100 flex-col-re">
        {/* <div class="uppercase text-7xl font-boldmargin-setts 2xl:ml-40 xl:ml-40 lg:ml-20 ml-20 mt-60">
          <span>Works done</span>
        </div> */}
        <div class=" margin-setts 2xl:pl-40 xl:pl-40 lg:pl-20  2xl:pr-40 xl:pr-40 lg:pr-20 lg:mt-40 grid md:grid-cols-2 md:gap-20 
         grid-cols-1 gap-20 pl-10 pr-10 mt-10
        
        2xl:text-4xl xl:text-4xl lg:text-3xl text-xl font-bold ">
          {posts.map((el,index) => {
            return <WorkBox el={el} index={index}></WorkBox>
          })}

        </div>
      </div>

      <div class="bg-white-100 flex-col-re ">
        <div class="margin-setts 2xl:mr-40 xl:pr-40 lg:pr-20 lg:mt-20 mt-10  flex-1 text-3xl font-bold"></div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Work;

export const pageQuery = graphql`
  {
    allSanityPost {
      nodes {
        title
        claim
        priority
        publish
        slug {
          current
        }
        mainImage {
          asset {
            url
          }
        }
        mainImageSbiadita {
          asset {
            url
          }
        }
        
      }
    }
  }
`;
